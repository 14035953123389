import React from 'react';
import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';

import Layout from '../components/layout';
import { CommonContext } from '../context';

function NotFoundPage({ location }) {
  const isBrowser = typeof window !== 'undefined' && window;
  return (
    <CommonContext.Provider value={{ location: location }}>
      {isBrowser && (
        <Layout title="Page Not Found" location={location}>
          <div className="flex justify-center px-6 py-6 md:py-16">
            <div className="w-full max-w-3xl pt-20 shadow-2xl rounded-2xl">
              <div className="text-center text-9xl font-regular">404</div>
              <div className="mt-6 mb-6 text-sm leading-6 text-center md:mt-8">
                Page not found!
              </div>
              <div className="mb-12 text-sm leading-6 text-center font-semiBold text-primary md:mb-52 md:text-base">
                <Link to="/">Go Back to homepage</Link>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </CommonContext.Provider>
  );
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
};

export default NotFoundPage;
